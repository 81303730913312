import React, {FC} from 'react'

import {unstable_getImgProps as getImgProps, ImageProps} from 'next/image'
interface IResponsiveSize {
	w: number
	h: number
}
interface IResponsiveSizes {
	base: IResponsiveSize
	md: IResponsiveSize
	lg: IResponsiveSize
	xl: IResponsiveSize
}
interface IImageSwitcherPropsBase extends Omit<ImageProps, 'sizes' | 'fill' | 'src'> {
	baseSrc: string
	wrapperClassName?: string
}

interface IImageSwitcherPropsFill extends IImageSwitcherPropsBase {
	fill: true
	sizes?: IResponsiveSizes
}

interface IImageSwitcherPropsSizes extends IImageSwitcherPropsBase {
	fill?: false
	sizes: IResponsiveSizes
}

type TImageSwitcherProps = IImageSwitcherPropsFill | IImageSwitcherPropsSizes

const ImageSwitcher: FC<TImageSwitcherProps> = ({className, baseSrc, sizes, ...props}) => {
	const withFormat = baseSrc.lastIndexOf('.') > -1
	const pathWithoutFormat = withFormat ? baseSrc.slice(0, baseSrc.lastIndexOf('.')) : baseSrc
	const format =
		baseSrc.lastIndexOf('.') > -1 ? baseSrc.substring(baseSrc.lastIndexOf('.') + 1) : 'svg'

	const getImagePropsWithSize = (size: IResponsiveSize | undefined, suffix: string) => {
		const {
			props: {srcSet, ...rest},
		} = getImgProps({
			...props,
			width: size?.w,
			height: size?.h,
			src: `${pathWithoutFormat}${suffix}.${format}`,
		})

		return {srcSet, rest}
	}

	const {rest} = getImagePropsWithSize(sizes?.base, '')
	const {srcSet: md} = getImagePropsWithSize(sizes?.md, '-md')
	const {srcSet: lg} = getImagePropsWithSize(sizes?.lg, '-lg')
	const {srcSet: xl} = getImagePropsWithSize(sizes?.xl, '-xl')

	return (
		<picture className={className}>
			<source media="(min-width: 1440px)" srcSet={xl} />
			<source media="(min-width: 1024px)" srcSet={lg} />
			<source media="(min-width: 768px)" srcSet={md} />
			<img className={className} alt={props.alt} {...rest} />
		</picture>
	)
}

ImageSwitcher.displayName = 'ImageSwitcher'

export default ImageSwitcher
