import React, {FC} from 'react'

import cn from 'classnames/bind'

import PortableBlocks from 'components/blocks/PortableBlocks'
import ToggleableTextGroup from 'components/elements/ToggleableTextGroup'
import {BlockContent} from 'src/types/__sanitySchema__'

import s from './SectionBlog.module.scss'
const cx = cn.bind(s)

interface ISectionBlogProps {
	className?: string
	items: {title?: string | undefined; description?: BlockContent | undefined}[] | undefined
}

/** SectionBlog block component */
const SectionBlog: FC<ISectionBlogProps> = ({className, items}) => {
	return (
		<div className={cx('wrapper', className)}>
			{items?.map((item) => {
				return (
					<div key={item.title} className={cx('item')}>
						<ToggleableTextGroup header={item.title || ''}>
							{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
							<PortableBlocks blockContent={item.description!} />
						</ToggleableTextGroup>
					</div>
				)
			})}
		</div>
	)
}

SectionBlog.displayName = 'SectionBlog'

export default SectionBlog
