import {FC, Suspense} from 'react'

import cn from 'classnames/bind'

import s from './ToggleableTextGroup.module.scss'
import Accordion from '../Accordion'
import ArrowDown from '../ArrowDown'

const cx = cn.bind(s)

interface IToggleableTextGroupProps {
	header: string
	headerClassName?: string
	bodyClassName?: string
}

const ToggleableTextGroup: FC<IToggleableTextGroupProps> = ({
	children,
	header,
	headerClassName,
	bodyClassName,
	...attrs
}) => (
	<Suspense fallback={<div>Loading..</div>}>
		<Accordion {...attrs}>
			<Accordion.Header className={cx('accordion-header')}>
				{(isOpened: boolean) => (
					<span className={cx('header', 'h3', headerClassName)}>
						<span className={cx('header__text', 'text')}>{header}</span>
						<span className={cx('header__icon')}>
							<ArrowDown isOpen={isOpened} />
						</span>
					</span>
				)}
			</Accordion.Header>
			<Accordion.Content>
				<span className={cx('item-body', bodyClassName)}>{children}</span>
			</Accordion.Content>
		</Accordion>
	</Suspense>
)

export default ToggleableTextGroup
