import {useContext, createContext, FC} from 'react'

import cn from 'classnames/bind'
import AnimateHeight from 'react-animate-height'

import {useToggleState} from 'src/utils/hooks/useToggleState'

import s from './Accordion.module.css'

const cx = cn.bind(s)

const Context = createContext<{
	isOpened?: boolean
	toggle?: () => void
	isDisabled?: boolean
}>({})

interface IAccordionProps {
	initialIsOpen?: boolean
	isDisabled?: boolean
}

export type TAccordionComponent<P = unknown> = FC<P> & {
	Header: FC<IAccordionHeaderProps>
	Content: FC<IAccordionContentProps>
}

const Accordion: TAccordionComponent<IAccordionProps> = ({
	children,
	initialIsOpen = false,
	isDisabled = false,
}) => {
	const [isOpened, toggle] = useToggleState(initialIsOpen)
	return (
		<Context.Provider value={{isOpened, isDisabled, toggle}}>
			{typeof children === 'function' ? children({isOpened}) : children}
		</Context.Provider>
	)
}

interface IAccordionHeaderProps {
	className?: string
}

const AccordionHeader: FC<IAccordionHeaderProps> = ({children, className}) => {
	const {isOpened, toggle, isDisabled} = useContext(Context)
	const onClick = () => {
		if (isDisabled) {
			return
		}
		toggle?.()
	}
	return (
		<button
			type="button"
			className={cx('header', className, {disabled: isDisabled})}
			aria-expanded={isOpened}
			onClick={onClick}>
			{typeof children === 'function' ? children(isOpened) : children}
		</button>
	)
}

interface IAccordionContentProps {}

const AccordionContent: FC<IAccordionContentProps> = ({children}) => {
	const {isOpened} = useContext(Context)

	return (
		<AnimateHeight
			duration={300}
			easing="ease-in-out"
			height={isOpened ? 'auto' : 0}
			className="accordion-content">
			{children}
		</AnimateHeight>
	)
}

Accordion.Header = AccordionHeader
Accordion.Content = AccordionContent

export default Accordion
