import {FC} from 'react'

import cn from 'classnames/bind'
import Image from 'next/legacy/image'

import s from './SectionPerfect.module.scss'
import {getBenefitBgColor} from '../utils'
const cx = cn.bind(s)

interface ISectionPerfectProps {
	className?: string
	items?: {
		title?: string
		description?: string
		icon?: string
		_key: string
	}[]
}

/** SectionPerfect block component */
const SectionPerfect: FC<ISectionPerfectProps> = ({className, items}) => {
	return (
		<ul className={cx('wrapper', className)}>
			{items?.map((item, idx) => (
				<li className={cx('item')} key={item._key || item.title}>
					<div className={cx('item__icon')} style={{backgroundColor: getBenefitBgColor(idx)}}>
						<Image
							layout="fixed"
							src={item.icon || ''}
							width="48"
							height="48"
							alt={`${item.title} icon`}
						/>
					</div>

					<div className={cx('item__content')}>
						<p className={cx('item__label', 'h2')}>{item.title}</p>
						<p className={cx('item__description')}>{item.description}</p>
					</div>
				</li>
			))}
		</ul>
	)
}

SectionPerfect.displayName = 'SectionPerfect'

export default SectionPerfect
