import {FC, useEffect} from 'react'

import cn from 'classnames/bind'

import DocumentToolsList from 'components/blocks/DocumentToolsList'
import {menuStubItems} from 'components/blocks/Header/Header'
import SectionBenefits from 'components/blocks/home/SectionBenefits'
import SectionBlog from 'components/blocks/home/SectionBlog'
import SectionFaq from 'components/blocks/home/SectionFaq'
import SectionGenerators from 'components/blocks/home/SectionGenerators'
import SectionMakeDocument from 'components/blocks/home/SectionMakeDocument'
import SectionPerfect from 'components/blocks/home/SectionPerfect'
import SectionPosts from 'components/blocks/home/SectionPosts'
import MenuStub from 'components/blocks/MenuStub'
import SectionTestimonials from 'components/blocks/SectionTestimonials'
import {Col, Container, Row} from 'components/elements/grid'
import HowItem from 'components/elements/HowItem'
import {HomePage} from 'src/types/__sanitySchema__'
import {TPost} from 'src/types/blog'
import {posthogTrack, klaviyoTrack} from 'src/utils'

import s from './TemplateHome.module.scss'

const cx = cn.bind(s)

interface ITemplateHomeProps {
	className?: string
	content: HomePage
}

const TemplateHome: FC<ITemplateHomeProps> = ({className, content}) => {
	useEffect(() => {
		posthogTrack('[HOME] Page opened')
		klaviyoTrack('Page opened', {page: 'home', href: window?.location?.href})
	}, [])

	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('top')}>
				<MenuStub
					className={cx('top__navigation', 'd-none', 'd-md-block')}
					menuItems={menuStubItems}
				/>
				<Container>
					<Row>
						<Col md={{span: 10, offset: 1}} lg={{span: 12, offset: 0}}>
							<h1 className={cx('top__title')}>{content.title}</h1>
							<p className={cx('top__subtitle')}>{content.subtitle}</p>
						</Col>
					</Row>
				</Container>
			</div>
			<div className={cx('tools-list')}>
				<DocumentToolsList className={cx('tools-list__component')} />
			</div>

			<section className={cx('section', 'benefits')}>
				<Container>
					<Row>
						<Col lg={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'benefits__title', 'h1')}>
									{content.why?.title}
								</h2>
								<p className={cx('section__subtitle', 'benefits__subtitle')}>
									{content.why?.subtitle}
								</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={{span: 10, offset: 1}}>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							<SectionBenefits items={(content.why?.items || []) as any} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className={cx('section', 'how')}>
				<Container>
					<Row className={cx('how__what-row')}>
						<Col md={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'benefits__title', 'h1')}>
									{content.what?.title}
								</h2>
								<p className={cx('section__subtitle', 'benefits__subtitle')}>
									{content.what?.description}
								</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'benefits__title', 'h1')}>
									{content.how?.title}
								</h2>
								<p className={cx('section__subtitle', 'benefits__subtitle')}>
									{content.how?.subtitle}
								</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={cx('how__list')}>
								{content.how?.items?.map((item, index) => (
									<HowItem
										className={cx('how__item')}
										key={item._key}
										step={index + 1}
										title={item.title || ''}
										description={item.description || ''}
									/>
								))}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className={cx('section', 'perfect')}>
				<Container>
					<Row>
						<Col xl={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'h1')}>{content.perfectFor?.title}</h2>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							<SectionPerfect items={content.perfectFor?.items as any} />
						</Col>
					</Row>
				</Container>
			</section>

			<section className={cx('section', 'testimonials')}>
				<Container>
					<Row>
						<Col xl={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'h1')}>{content.reviews?.title}</h2>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xl={{span: 10, offset: 1}} xxl={{span: 12, offset: 0}}>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							<SectionTestimonials items={content.reviews?.items as any} />
						</Col>
					</Row>
				</Container>
			</section>

			<section className={cx('section', 'faq')}>
				<SectionGenerators items={content.generatorsSection?.items} />
			</section>

			<section className={cx('section')}>
				<Row>
					<Col xl={{span: 10, offset: 1}}>
						<div className={cx('section__title-block')}>
							<h2 className={cx('section__title', 'h1')}>Generate your paystub now!</h2>
						</div>
					</Col>
				</Row>
				<DocumentToolsList withPreview={false} />
			</section>

			<section className={cx('section')}>
				<Container>
					<Row>
						<Col xl={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'h1')}>{content.posts?.title}</h2>
							</div>
						</Col>
					</Row>

					<Row>
						<Col>
							<SectionPosts posts={(content.posts?.posts as unknown as TPost[]) || []} />
						</Col>
					</Row>
				</Container>
			</section>

			<section className={cx('section')}>
				<Container>
					<Row>
						<Col xl={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'h1')}>{content.blogSection?.title}</h2>
							</div>
						</Col>
						<Col>
							<SectionBlog items={content.blogSection?.items} />
						</Col>
					</Row>
				</Container>
			</section>

			<section className={cx('section', 'faq')}>
				<Container>
					<Row>
						<Col xl={{span: 10, offset: 1}}>
							<div className={cx('section__title-block')}>
								<h2 className={cx('section__title', 'h1')}>{content.faq?.title}</h2>
							</div>
						</Col>
					</Row>
					<Row>
						<SectionFaq items={content.faq?.items} />
					</Row>
				</Container>
			</section>

			<section className={cx('section', 'make-document')}>
				<Container className={cx('make-document__container')}>
					<Row>
						<Col xl={{offset: 2, span: 8}}>
							<h2 className={cx('section__title', 'h1')}>let&#39;s Get Started</h2>
							<SectionMakeDocument />
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	)
}

TemplateHome.displayName = 'TemplateHome'

export default TemplateHome
