import {FC} from 'react'

import cn from 'classnames/bind'

import ArrowDownIcon from 'assets/icons/arrow-down.svg'

import s from './ArrowDown.module.scss'

const cx = cn.bind(s)

interface IArrowDownProps {
	className?: string
	isOpen: boolean
}

const ArrowDown: FC<IArrowDownProps> = ({className, isOpen, ...attrs}) => (
	<ArrowDownIcon
		className={cx(className, 'arrow', {
			arrowActive: isOpen,
		})}
		{...attrs}
	/>
)

export default ArrowDown
