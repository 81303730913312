import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './HowItem.module.scss'
const cx = cn.bind(s)

interface IHowItemProps {
	className?: string
	step: number
	title: string
	description: string
}

/** HowItem block component */
const HowItem: FC<IHowItemProps> = ({className, step, title, description}) => {
	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('step')}>Step {step}</div>
			<header className={cx('title')}>{title}</header>
			<p className={cx('description')}>{description}</p>
		</div>
	)
}

HowItem.displayName = 'HowItem'

export default HowItem
