import React, {FC} from 'react'

import cn from 'classnames/bind'

import {Col, Container, Row} from 'components/elements/grid'
import ImageSwitcher from 'components/elements/ImageSwitcher'

import s from './SectionGenerators.module.scss'
const cx = cn.bind(s)

interface ISectionGeneratorsProps {
	className?: string
	items: {title?: string | undefined; description?: string | undefined}[] | undefined
}

/** SectionGenerators block component */
const SectionGenerators: FC<ISectionGeneratorsProps> = ({className, items}) => {
	return (
		<div className={cx('wrapper', className)}>
			<Container>
				<Row>
					<Col md={{offset: 1, span: 10}} lg={{span: 5, offset: 0}}>
						<div className={s.banner}>
							<ImageSwitcher
								className={s.banner__picture}
								baseSrc="/images/home/generators-banner"
								alt="Illustration of choosing of the document generator"
								sizes={{
									base: {
										w: 375,
										h: 536,
									},
									md: {w: 568, h: 451},
									lg: {w: 348, h: 543},
									xl: {w: 443, h: 624},
								}}
							/>
						</div>
					</Col>
					<Col lg={{span: 7}}>
						<ul className={s.list}>
							{items?.map(({title, description}) => (
								<li className={s.list__item} key={title}>
									<h2 className={cx('list__label', 'h2')}>{title}</h2>
									<p className={cx('list__description')}>{description}</p>
								</li>
							))}
						</ul>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

SectionGenerators.displayName = 'SectionGenerators'

export default SectionGenerators
