import React, {FC, ReactNode} from 'react'

import cn from 'classnames/bind'

import s from './Table.module.scss'
const cx = cn.bind(s)

interface ICell {
	value: ReactNode
	colSpan?: number
	rowSpan?: number
	key: string
}

interface ITableProps {
	className?: string
	data: {key: string; cells: ICell[]}[]
	headers: ICell[]
}

const Table: FC<ITableProps> = ({className, headers = [], data = [], ...props}) => {
	return (
		<div className={cx('table-wrapper')}>
			<table className={cx('table', className)} {...props}>
				{headers.length ? (
					<thead>
						<tr>
							{headers.map((header) => (
								<th key={header.key} className={cx('t-cell')}>
									{header.value}
								</th>
							))}
						</tr>
					</thead>
				) : null}
				<tbody>
					{data.map((row) => (
						<tr key={row.key} className={cx('t-row')}>
							{row.cells.map((cell) => (
								<td
									key={cell.key}
									colSpan={cell.colSpan}
									rowSpan={cell.rowSpan}
									className={cx('t-cell')}>
									{cell.value}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

Table.displayName = 'Table'

export default Table
