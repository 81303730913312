import React, {FC} from 'react'

import cn from 'classnames/bind'

import s from './LayoutHome.module.scss'
import LayoutDefault from '../LayoutDefault'

const cx = cn.bind(s)

interface ILayoutHomeProps {
	className?: string
}

const LayoutHome: FC<ILayoutHomeProps> = ({className, children}) => {
	return (
		<LayoutDefault hasMenuStub={false} className={cx('wrapper', className)}>
			{children}
		</LayoutDefault>
	)
}

LayoutHome.displayName = 'LayoutHome'

export default LayoutHome
