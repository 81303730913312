import React, {FC} from 'react'

import cn from 'classnames/bind'
import Link from 'next/link'

import Button from 'components/elements/Button'
import {IMenuItem} from 'src/types'
import {posthogTrack} from 'src/utils'

import s from './SectionMakeDocument.module.scss'
const cx = cn.bind(s)

const data: IMenuItem[] = [
	{slug: '/make-your-paystub', title: 'Make Pay Stub Now!'},
	{slug: '/make-your-w2', title: 'Make W-2 Now!'},
	{slug: '/make-your-1099', title: 'Make 1099 Now!'},
]

interface ISectionMakeDocumentProps {
	className?: string
}

const SectionMakeDocument: FC<ISectionMakeDocumentProps> = ({className}) => {
	return (
		<div className={cx('wrapper', className)}>
			<ul className={s.list}>
				{data.map(({slug, title}) => (
					<li key={title} className={s.list__item}>
						<Link href={slug} passHref legacyBehavior>
							<Button
								size="responsive"
								className={s.list__btn}
								as="a"
								onClick={() => {
									posthogTrack("[HOME][Let's make a document] Button clicked", {document: slug})
								}}>
								{title}
							</Button>
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}

SectionMakeDocument.displayName = 'SectionMakeDocument'

export default SectionMakeDocument
