import {FC} from 'react'

import cn from 'classnames/bind'

import {Col, Row} from 'components/elements/grid'
import ImageSwitcher from 'components/elements/ImageSwitcher'

import s from './SectionFaq.module.scss'
const cx = cn.bind(s)

interface ISectionFaqProps {
	items: {title?: string | undefined; description?: string | undefined}[] | undefined
}

const SectionFaq: FC<ISectionFaqProps> = ({items}) => {
	return (
		<Row>
			<Col md={{offset: 1, span: 10}} lg={{span: 5, offset: 0}}>
				<div className={s.banner}>
					<ImageSwitcher
						className={s.banner__picture}
						baseSrc="/images/home/home-banner"
						alt="General banner"
						sizes={{
							base: {w: 375, h: 536},
							md: {w: 568, h: 451},
							lg: {w: 348, h: 543},
							xl: {w: 400, h: 704},
						}}
					/>
				</div>
			</Col>
			<Col lg={{span: 7, order: 'first'}}>
				<ul className={s.list}>
					{items?.map(({title, description}) => (
						<li className={s.list__item} key={title}>
							<h2 className={cx('list__label', 'h2')}>{title}</h2>
							<p className={cx('list__description')}>{description}</p>
						</li>
					))}
				</ul>
			</Col>
		</Row>
	)
}

SectionFaq.displayName = 'SectionFaq'

export default SectionFaq
