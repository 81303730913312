import {FC} from 'react'

import cn from 'classnames/bind'
import Image from 'next/legacy/image'

import s from './SectionBenefits.module.scss'
import {getBenefitBgColor} from '../utils'

const cx = cn.bind(s)

interface ISectionBenefitsProps {
	className?: string
	items?: {
		title?: string
		description?: string
		icon?: string
		_key: string
	}[]
}

const SectionBenefits: FC<ISectionBenefitsProps> = ({className, items}) => {
	return (
		<div className={cx('wrapper', className)}>
			<ul className={s.list}>
				{items?.map((benefit, idx) => (
					<li className={s.list__item} key={benefit._key || benefit.title}>
						<Benefit {...benefit} icon={benefit.icon || ''} color={getBenefitBgColor(idx)} />
					</li>
				))}
			</ul>
		</div>
	)
}

SectionBenefits.displayName = 'SectionBenefits'

export default SectionBenefits

interface IBenefit {
	title?: string
	description?: string
	icon: string
	color: string
}

const Benefit: FC<IBenefit> = ({title, description, icon, color}) => {
	return (
		<div className={cx('benefit')}>
			<div className={cx('benefit__icon')} style={{backgroundColor: color}}>
				<Image layout="fixed" src={icon} width="48" height="48" alt={`${title} icon`} />
			</div>

			<div className={cx('benefit__content')}>
				<p className={cx('benefit__label', 'h2')}>{title}</p>
				<p className={cx('benefit__description')}>{description}</p>
			</div>
		</div>
	)
}
