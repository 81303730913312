import {FC, ReactNode} from 'react'

import {PortableText} from '@portabletext/react'
import {SanityImageSource} from '@sanity/image-url/lib/types/types'
import cn from 'classnames/bind'
import Image from 'next/image'
import Link from 'next/link'

import Table from 'components/elements/Table'
import {BlockContent} from 'src/types/__sanitySchema__'
import {getImageBuilderFor} from 'src/utils/sanityImageBuilder'

import s from './PortableBlocks.module.scss'

const cx = cn.bind(s)

const getImageSizeFromURL = (url: string) => {
	let width = 0
	let height = 0
	if (url) {
		const fileName = url.split('.').slice(0, -1).join('.')
		if (fileName) {
			const sizes = fileName.split('-').slice(-1)[0]
			if (sizes) {
				const sizesParts = sizes.split('x')
				if (sizesParts[0]) {
					width = +sizesParts[0]
				}
				if (sizesParts[1]) {
					height = +sizesParts[1]
				}
			}
		}
	}
	return {width, height}
}

const DetailedList: FC<{value: {items: {title: string; description: BlockContent}[]}}> = ({
	value,
}) => {
	return (
		<ol className={cx('detailed-list')}>
			{value.items.map((item, idx) => {
				return (
					<li className={cx('detailed-list__item')} key={idx}>
						<p className={cx('fs-tab', 'detailed-list__label')}>{item.title}</p>
						<div className={cx('detailed-list__description')}>
							<PortableText value={item.description} />
						</div>
					</li>
				)
			})}
		</ol>
	)
}

const ImageNode: FC<{value: SanityImageSource}> = ({value}) => {
	const alt = typeof value === 'object' ? (value as any).alt : ''
	let url
	try {
		url = getImageBuilderFor(value).format('webp').url()
	} catch (error) {}

	if (!url) return null

	const {width, height} = getImageSizeFromURL(url)

	return (
		<div className={cx('block-image')}>
			<Image
				className={cx('img')}
				sizes="(max-width: 768px) 100vw, (max-width:1919px) 728px, 848px"
				src={url}
				width={width}
				height={height}
				alt={alt}
			/>
		</div>
	)
}

interface ISanityTableProp {
	value: {
		_key: string
		_type: 'myTable'
		rows: {
			_key: string
			_type: ['myTable.row']
			cells: {
				data?: {
					contents: any[]
				}
				colSpan: number
				rowSpan: number
				_key: string
				type: ['myTable.cell']
			}[]
		}[]
	}
}

const SanityTable: FC<ISanityTableProp> = ({value}) => {
	const [headerRow, ...rows] = value.rows

	const getHeaders = () =>
		headerRow.cells.map((cell) => ({
			key: cell._key,
			value: <PortableText value={cell.data?.contents} />,
			colSpan: cell.colSpan,
			rowSpan: cell.rowSpan,
		}))

	const getRows = () => {
		return rows.map((row) => ({
			key: row._key,
			cells: row.cells.map((cell) => ({
				key: cell._key,
				value: <PortableText value={cell.data?.contents} />,
				colSpan: cell.colSpan,
				rowSpan: cell.rowSpan,
			})),
		}))
	}

	return <Table headers={getHeaders()} data={getRows()} />
}
const TabText: FC = ({children}) => {
	return <h2 className="fs-tab">{children}</h2>
}

const OrangeText: FC = ({children}) => {
	return <span className={cx('orange')}>{children}</span>
}

const CustomLink: FC<{
	children: ReactNode

	text: string
	value?: {
		nofollow: boolean
		_type: 'link'
		href: string
		_key: string
	}
	markType: string
	markKey?: string | undefined
}> = ({children, value}) => {
	const origin = typeof window !== 'undefined' ? window.location.origin : ''

	if (!value?.href) {
		return <span>{children}</span>
	}
	return (
		<Link
			href={value.href}
			target={origin && value.href.includes(origin) ? undefined : '_blank'}
			{...(value.nofollow ? {rel: 'noopener noreferrer nofollow'} : {})}>
			{children}
		</Link>
	)
}

interface IPortableBlocksProps {
	className?: string
	blockContent: BlockContent
}

const PortableBlocks: FC<IPortableBlocksProps> = ({className, blockContent}) => {
	return (
		<div className={cx('wrapper', className)}>
			<PortableText
				value={blockContent}
				components={{
					marks: {orange: OrangeText, link: CustomLink},
					block: {tab: TabText},
					types: {
						'detailed-list': DetailedList,
						image: ImageNode,
						myTable: SanityTable,
					},
				}}
			/>
		</div>
	)
}

PortableBlocks.displayName = 'PortableBlocks'

export default PortableBlocks
