import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
	dataset: process.env.NEXT_PUBLIC_SANITY_DB,
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
})

export function getImageBuilderFor(source: Parameters<typeof builder.image>['0']) {
	return builder.image(source).quality(100)
}
