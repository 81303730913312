import {GetStaticProps} from 'next'
import Head from 'next/head'

import SEO from 'components/elements/SEO'
import LayoutHome from 'components/layouts/LayoutHome'
import TemplateHome from 'components/templates/TemplateHome'
import sanityClient from 'src/backend/lib/sanityClient'
import {TPageComponent} from 'src/types'
import {HomePage} from 'src/types/__sanitySchema__'

interface IHomeProps {
	pageContent: HomePage
}

const Home: TPageComponent<IHomeProps> = ({pageContent}) => {
	return (
		<>
			<SEO
				title="Online Paystub Generator | 3-Step Stub Maker - Paystub.org"
				description="Create paystubs, 1099-MISC, and W-2 forms in three easy steps with Paystub.org, an online paystub generator for businesses, freelancers & entrepreneurs."
				keywords="online paystub generator"
			/>
			<Head>
				<meta name="trustburn-site-verification" content="d8622d1ecec3d8092cfdaeba4aa65098" />
			</Head>

			<TemplateHome content={pageContent} />
		</>
	)
}

Home.Layout = LayoutHome

export default Home

export const getStaticProps: GetStaticProps<IHomeProps> = async () => {
	try {
		const query = `
			*[_type == "homePage"]{
				...,
				why{
					...,
					items[]{
						...,
						"icon": icon.asset->url
					}
				},
				perfectFor{
					...,
					items[]{
						...,
						"icon": icon.asset->url
					}
				},
				posts{
					...,
					posts[]->{
						...,
						"mainImage": mainImage.asset->url
					}
				},
				reviews{
					...,
					items[]{
						...,
						"photo": photo.asset->url
					}
				}
			}
		`

		const content = (await sanityClient.fetch<HomePage[]>(query))[0]

		return {
			props: {
				pageContent: content,
			},
		}
	} catch (e) {
		return {
			notFound: true,
		}
	}
}
