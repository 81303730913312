import {FC} from 'react'

import cn from 'classnames/bind'
import Image from 'next/legacy/image'
import {Navigation, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'

import IconPlaceholder from 'src/assets/icons/testimonials-placeholder.svg'

import 'swiper/css'

import s from './SectionTestimonials.module.scss'

const cx = cn.bind(s)

export interface ISectionTestimonialsItem {
	title: string | undefined
	name: string | undefined
	description: string | undefined
	photo: string | undefined
}

interface ISectionTestimonialsProps {
	className?: string
	items: ISectionTestimonialsItem[]
}

/** SectionTestimonials block component */
const SectionTestimonials: FC<ISectionTestimonialsProps> = ({className, items}) => {
	return (
		<div className={cx('wrapper', className)}>
			<Swiper
				resizeObserver
				autoplay={{
					pauseOnMouseEnter: true,
					delay: 5000,
				}}
				breakpoints={{
					320: {slidesPerView: 1},
					768: {slidesPerView: 2},
					1920: {slidesPerView: 3},
				}}
				navigation={items.length > 2}
				modules={[Navigation, Autoplay]}
				className={cx('slider')}>
				{items.map((item, i) => (
					<SwiperSlide className={cx('slide')} key={i}>
						<SlideContent {...item} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

SectionTestimonials.displayName = 'SectionTestimonials'

export default SectionTestimonials

const SlideContent: FC<ISectionTestimonialsItem> = ({title, name, description, photo}) => {
	return (
		<div className={cx('content')}>
			{photo ? (
				<Image
					className={cx('content__icon')}
					layout="fixed"
					width={50}
					height={50}
					src={photo}
					alt={title}
				/>
			) : (
				<IconPlaceholder />
			)}

			<p className={cx('content__title')}>“{title}”</p>
			<p className={cx('content__description')}>{description}</p>
			<address className={cx('content__author')}>
				<span>{name}</span>
			</address>
		</div>
	)
}
