import React, {FC} from 'react'

import cn from 'classnames/bind'

import BlogItem from 'components/blocks/BlogItem'
import {TPost} from 'src/types/blog'

import s from './SectionPosts.module.scss'
const cx = cn.bind(s)

interface ISectionPostsProps {
	className?: string
	posts: TPost[]
}

/** SectionPosts block component */
const SectionPosts: FC<ISectionPostsProps> = ({className, posts}) => {
	return (
		<div className={cx('wrapper', className)}>
			{posts.map((post, index) => (
				<BlogItem key={post._id} className={cx('post')} post={post} isVertical index={index} />
			))}
		</div>
	)
}

SectionPosts.displayName = 'SectionPosts'

export default SectionPosts
